/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ImgExplanation from '../components/ImgExplanation';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    ul: "ul",
    li: "li",
    ol: "ol"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "What are the differences between the two types of emails and how do you send each of them?"), "\n", React.createElement(_components.p, null, "We will need to get a bit technical at points in this article, but we have done our best to keep it approachable for everyone."), "\n", React.createElement(_components.h2, {
    id: "plain-text-email",
    style: {
      position: "relative"
    }
  }, "Plain Text Email", React.createElement(_components.a, {
    href: "#plain-text-email",
    "aria-label": "plain text email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Surprise, you already know what this is!"), "\n", React.createElement(_components.p, null, "A plain text email is a fancy name for the type of email the average person types out and sends on any given day."), "\n", React.createElement(ImgExplanation, {
    explanation: "Text-only email."
  }, React.createElement("img", {
    src: "/text_only_review_request_email_example.png",
    alt: "Text-only email."
  })), "\n", React.createElement(_components.p, null, "The only time anyone uses the term \"plain text email\" to describe this kind of email is when they are trying to differentiate it from HTML emails."), "\n", React.createElement(_components.p, null, "If you write an email using G Mail, Outlook, Yahoo Mail or the mail app on your phone you are sending a plain text email."), "\n", React.createElement(_components.h3, {
    id: "sending-a-plain-text-email",
    style: {
      position: "relative"
    }
  }, "Sending a Plain Text Email", React.createElement(_components.a, {
    href: "#sending-a-plain-text-email",
    "aria-label": "sending a plain text email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Plain text emails are easy to write and send, anyone can do it. In fact everyone reading this article almost certainly has."), "\n", React.createElement(_components.p, null, "Just open up your email application, type out your message and hit send."), "\n", React.createElement(_components.p, null, "The features available in each email application will be different. Some include advanced features like delayed send."), "\n", React.createElement(_components.p, null, "This can be a useful tool for businesses that want their emails delivered to their customers inbox at the perfect time."), "\n", React.createElement(_components.h3, {
    id: "advantages-of-a-plain-text-email",
    style: {
      position: "relative"
    }
  }, "Advantages of a Plain Text Email", React.createElement(_components.a, {
    href: "#advantages-of-a-plain-text-email",
    "aria-label": "advantages of a plain text email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Easy to get started with"), "\n", React.createElement(_components.li, null, "Free"), "\n"), "\n", React.createElement(_components.h3, {
    id: "disadvantages-of-a-plain-text-email",
    style: {
      position: "relative"
    }
  }, "Disadvantages of a Plain Text Email", React.createElement(_components.a, {
    href: "#disadvantages-of-a-plain-text-email",
    "aria-label": "disadvantages of a plain text email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Time consuming to write a large number of them"), "\n", React.createElement(_components.li, null, "Not as flashy as HTML emails"), "\n"), "\n", React.createElement(_components.h2, {
    id: "html-email",
    style: {
      position: "relative"
    }
  }, "HTML Email", React.createElement(_components.a, {
    href: "#html-email",
    "aria-label": "html email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A HTML email is a type of email that is written in HTML code."), "\n", React.createElement(ImgExplanation, {
    explanation: "Plain text email."
  }, React.createElement("img", {
    src: "/html_email_example.jpg",
    alt: "HTML email."
  })), "\n", React.createElement(_components.p, null, "When an email is written using html code it means in addition to the text you see when you are viewing the email, the email contains additional structural and styling information."), "\n", React.createElement(_components.p, null, "The additional information tells the email application how to display the parts of the email you do see. Things like, the color, spacing and size of text and elements."), "\n", React.createElement(_components.p, null, "Some of you may know that HTML code was originally used to build web pages. It was only when people started wanting to make their emails more customized that HTML code began to be supported by email applications."), "\n", React.createElement(_components.p, null, "Most email recipients don't know the difference between a plain text email or a HTML email. However, what they have come to expect is that an email from a business will look different from an email from their friend."), "\n", React.createElement(_components.p, null, "For example having the companies logo at the top of the email and the companies color scheme throughout adds a level of professionalism."), "\n", React.createElement(_components.h3, {
    id: "sending-a-html-email",
    style: {
      position: "relative"
    }
  }, "Sending a HTML Email", React.createElement(_components.a, {
    href: "#sending-a-html-email",
    "aria-label": "sending a html email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Sending a HTML is more difficult than sending a plain text email. However, the many advantages of an HTML email make it worth the effort."), "\n", React.createElement(_components.p, null, "These instructions assume you already have a file with HTML email code that is ready to send."), "\n", React.createElement(_components.p, null, "If you don't, you can head over to our collection of ", React.createElement("a", {
    href: "/html-review-request-emails",
    title: "HTML email template collection page"
  }, "HTML email templates"), " and download one before completing the following."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Right click on the html file and choose to open it in your web browser"), "\n", React.createElement(_components.li, null, "Select the contents of the window with Ctrl+A (Windows) / Cmd+A (Mac) followed by Ctrl+C (Windows) / Cmd+C (Mac)"), "\n", React.createElement(_components.li, null, "Paste it into your Gmail compose window with Ctrl+V (Windows) / Cmd+V (Mac) and click send"), "\n"), "\n", React.createElement(_components.h3, {
    id: "advantages-of-a-html-email",
    style: {
      position: "relative"
    }
  }, "Advantages of a HTML Email", React.createElement(_components.a, {
    href: "#advantages-of-a-html-email",
    "aria-label": "advantages of a html email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "HTML emails give off a more professional appearance"), "\n", React.createElement(_components.li, null, "With images and colors they are more attention grabbing"), "\n", React.createElement(_components.li, null, "Calls to action (buttons) can be made more prominent / appealing"), "\n"), "\n", React.createElement(_components.h3, {
    id: "disadvantages-of-a-html-email",
    style: {
      position: "relative"
    }
  }, "Disadvantages of a HTML Email", React.createElement(_components.a, {
    href: "#disadvantages-of-a-html-email",
    "aria-label": "disadvantages of a html email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "More difficult to create than plain text emails"), "\n", React.createElement(_components.li, null, "Potentially requires new software to send (may cost money)"), "\n", React.createElement(_components.li, null, "More testing required, as the same HTML email may display differently on each email application"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
